<template>
  <v-container class="cases-show">
    <modal
      btn-primary-label="Sim"
      btn-secondary-color="Não"
      :show="showModalComplete"
      @close="closeModalComplete"
      @submit="saveModalComplete">
      <span slot="title">Encerrar</span>
      <div slot="content">
        <p class="mt-3">
          Tem certeza que deseja encerrar a videoconferência em andamento?
        </p>
      </div>
    </modal>

    <modal
      :show="videoConferenceShow"
      fullscreen
      central
      hide-actions
      max-width="100%"
      @close="closeModalVideoconference">
      <span slot="title">Video Conferência</span>
      <video-conference
        v-if="videoConferenceShow"
        slot="content" />
    </modal>

    <modal
      :show="showModal"
      fullscreen
      central
      hide-actions
      @close="closeModal"
      @submit="saveModal">
      <span
        v-if="proposal"
        slot="title">Proposta - {{ proposal.code }}</span>
      <accept-term
        v-if="showModal"
        slot="content"
        :proposal="proposal"
        @actionExecuted="termAction"
        @closeModal="closeModal" />
    </modal>

    <modal
      :show="showSurveyModal"
      :valid="isSurveyValid"
      content-class="survey-modal"
      fullscreen
      central
      @submit="submitSurvey"
      @close="closeSurveyModal">
      <span slot="title">Pesquisa de Satisfação</span>

      <div slot="content">
        <survey
          v-if="caseRecord"
          :kind="caseRecord.state"
          :company="caseRecord.companyName"
          :subject="caseRecord.administratorName"
          @updateRating="updateRating" />
      </div>
    </modal>

    <modal
      :show="showModalProposal"
      fullscreen
      central
      hide-actions
      @close="closeModalProposal">
      <span slot="title">Criar uma nova proposta</span>
      <proposal-create
        v-if="showModalProposal"
        slot="content"
        @cancel="closeModalProposal" />
    </modal>

    <v-dialog
      content-class="sideModal ma-0 d-flex"
      :value="true"
      persistent
      no-click-animation
      :width="width"
      @click:outside="close">
      <v-card class="primary" min-width="98" width="98">
        <v-navigation-drawer
          v-model="openMenu"
          persistent
          mini-variant
          enable-resize-watcher
          absolute
          dark
          mini-variant-width="80"
          class="primary"
          stateless
          hide-overlay
          mobile-breakpoint="600">
          <v-list color="primary" class="menu-list justify-center py-0">
            <v-list-item
              v-for="(menu, index) in itemsMenu"
              :key="menu.label"
              ref="menu"
              :aria-selected="menu.active"
              :to="{ name: menu.to.name }"
              role="menu-item"
              :aria-label="menu.label">
              <v-tooltip
                :ref="index"
                right>
                <template v-slot:activator="{ on }">
                  <font-awesome-icon
                    v-if="menu.label === 'Propostas'"
                    :icon="menu.icon" />
                  <img
                    v-else-if="menu.label === 'Integração Pagar.me'"
                    width="24px"
                    height="24px"
                    src="@/assets/images/pagarme.svg">
                  <v-icon
                    v-else
                    v-on="on">
                    {{ menu.icon }}
                  </v-icon>
                </template>
                <span>{{ menu.label }}</span>
              </v-tooltip>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-card v-if="$route.name !== 'CaseShow'" class="sideBarPanel py-5 pl-2 pr-5" min-width="333" width="333">
        <router-view :key="$route.fullPath" />
      </v-card>
      <v-card class="chatContent">
        <chat-messages
          v-if="caseRecord && caseRecord.path"
          empty-class="absolute-center"
          :disabled="chatDisabled"
          :thread="caseRecord.path"
          @createProposal="createProposal" />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import ContextualArea from '@/components/ui/ContextualArea'
  import ChatMessages from '@/components/ui/ChatMessages/List'
  import ProposalCreate from '@/components/Cases/Context/ProposalCreate'
  import Modal from '@/components/ui/Modal'
  import AcceptTerm from '@/components/Cases/Negotiation/AcceptTerm'
  import VideoConference from '@/components/VideoConference/VideoConference'
  import Survey from '@/components/Cases/Survey'
  import { link } from '@/utils/icons'
  import { mapGetters } from 'vuex'

  export default {
    name: 'ShowCase',
    components: { AcceptTerm, Modal, ContextualArea, VideoConference, Survey, ChatMessages, ProposalCreate },
    data: () => ({
      openMenu: true,
      showModal: false,
      showModalProposal: false,
      proposalId: null,
      loadingProposal: 0,
      openContextualArea: false,
      showModalComplete: false,
      showSurveyModal: false,
      surveyRatings: {},
      isSurveyValid: false,
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: -1,
        sortBy: 'parcel',
        totalItems: 0
      },
      menuLinks: [
        {
          label: 'Informações Gerais',
          to: { name: 'CaseGeneral' },
          icon: link.info,
          active: false
        },
        {
          label: 'Timeline',
          to: { name: 'CaseTimeline' },
          icon: link.timeline,
          active: false
        },
        {
          label: 'Títulos',
          to: { name: 'CaseTicketsList' },
          icon: link.tickets_case,
          active: false
        },
        {
          label: 'Propostas',
          to: { name: 'CaseProposalsList' },
          icon: link.proposals,
          active: false
        },
        {
          label: 'Documentos',
          to: { name: 'CaseDocuments' },
          icon: link.attach,
          active: false
        },
        {
          label: 'Canais de Comunicação',
          to: { name: 'CaseChannelsList' },
          icon: link.channels,
          active: false
        },
        {
          label: 'Minhas Anotações',
          to: { name: 'CaseNotesList' },
          icon: link.notes,
          active: false
        },
        {
          label: 'Agenda',
          to: { name: 'CaseScheduleList' },
          icon: link.schedule,
          active: false
        }
      ]
    }),
    computed: {
      ...mapGetters({
        isGuest: 'user/isGuest',
        caseRecord: 'cases/record',
        proposal: 'proposal/record',
        loading: 'app/getLoading',
        videoConferenceShow: 'app/videoConferenceShow',
        videoConferenceRoom: 'app/videoConferenceRoom',
        proposalFirst: 'proposal/firstActive'
      }),
      chatDisabled () {
        return this.caseRecord && this.caseRecord.state !== 'negotiation' || this.getGroups.includes('superuser') || this.getGroups.includes('administrator')
      },
      itemsMenu () {
        return this.menuLinks.filter(item => {
          return this.caseRecord && this.caseRecord.state === 'directNegotiation' ? !['CaseChat', 'CaseChannelsList', 'CaseNotesList', 'CaseScheduleList'].includes(item.to.name) : this.$can(item.to.name)
        })
      },
      width () {
        const width = 880
        const column = 333
        return this.caseRecord.activeConference ? width + column : width
      }
    },
    mounted () {
      this.openContextualArea = true
      window.openModalTerm = this.openModalTerm

      const caseId = this.$route.params.caseId

      this.$store.dispatch('cases/get', caseId)
      this.$store.dispatch('cases/videoConferenceInProgress', caseId)
    },
    beforeMount () {
      this.$nextTick(() => {
        this.$store.dispatch('notifications/read', this.$route.params.caseId)
      })
      this.$store.commit('app/setLoading', false)
    },
    methods: {
      createProposal () {
        this.showModalProposal = true
      },
      closeModalProposal () {
        this.showModalProposal = false
      },
      close () {
        this.$router.push({ name: 'Cases' })
      },
      menuClicked (routeName) {
        this.$router.push({ name: routeName }).catch(error => {})
      },
      closeModal () {
        this.showModal = false
        this.proposalId = null
      },
      closeModalComplete () {
        this.showModalComplete = false
      },
      closeModalVideoconference () {
        this.showModalComplete = true
      },
      saveModalComplete () {
        this.$store.dispatch('cases/videoConferenceComplete', this.videoConferenceRoom).then(() => {
          this.showModalComplete = false
          this.$root.$emit('snackbar', {show: true, color: 'success', text: 'A videoconferência foi encerrada'})
          this.$store.dispatch('app/videoConference', { room: null, show: false })
        })
      },
      saveModal () {
        console.log('salvando conteudo do modal')
      },
      openModalTerm (proposalId) {
        let state = this.caseRecord && this.caseRecord.state
        if (state === 'accepted' || state === 'refused') {
          this.$root.$emit('snackbar', {show: true, time: 6000, color: 'success', text: 'Proposta indisponível'})
        } else {
          this.showModal = true
          this.$store.dispatch('proposal/get', { proposalId, recalculate: false })
        }
      },
      termAction (action) {
        this.showModal = false
        this.showSurveyModal = true
      },
      updateRating (ratings) {
        this.surveyRatings = ratings

        this.isSurveyValid = Object.keys(this.surveyRatings).length === 5
      },
      submitSurvey () {
        const answerId = this.$uuid.v4()
        const data = {
          surveyResults: this.surveyRatings,
          companyId: this.caseRecord.companyId,
          answerId: answerId
        }
        this
          .$store
          .dispatch('surveys/submitSurvey', data)
          .then(this._successSurvey)
          .catch(this._failureSurvey)
      },
      closeSurveyModal () {
        this.showSurveyModal = false
      },
      _successSurvey () {
        this.showSurveyModal = false
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Pesquisa enviada com sucesso!'})
      },
      _failureSurvey () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'Falha no envio da pesquisa.'})
      },
    }
  }
</script>

<style lang="sass">
  .survey-modal .modal-content
    height: calc(100vh - 160px)
    flex: unset !important

  .sideModal
    .v-card
      background: #F2F2F7
      border-radius: 20px 0 0 20px !important
      &:not(:first-child)
        margin-left: -17px
        z-index: 1
      &:last-child
        background: #E5E5EA
  .sideBarPanel ~ .chatContent
    width: 484px
</style>
