<template>
  <tr
    v-if="table && !inline"
    class="flex py-0 display-field xs12 sm12">
    <td
      data-cy="display-label"
      class="table-text text-left font-weight-bold">
      {{ label }}
    </td>

    <td class="table-text text-right">
      <a
        v-if="isLink"
        :href="to"
        class="table-text text-right">{{ prefix }} {{ valueProcessed }} {{ suffix }}</a>
      <span
        v-else
        data-cy="display-value"
        class="table-text text-right">{{ prefix }} {{ valueProcessed }} {{ suffix }}</span>
      <slot />
    </td>
  </tr>
  <div
    v-else-if="inline && !table"
    class="flex py-0 display-field xs12 sm12">
    <span
      data-cy="display-label"
      class="darkGray--text mb-0 body-2">{{ label }}</span>
    <a
      v-if="isLink"
      :href="to"
      class="body-2 mb-0 grey--text">{{ prefix }} {{ valueProcessed }} {{ suffix }}</a>
    <span
      v-else
      data-cy="display-value"
      class="body-2 mb-0 grey--text">{{ prefix }} {{ valueProcessed }} {{ suffix }}</span>
    <slot />
  </div>
  <div
    v-else
    class="flex py-0 display-field xs12 sm12">
    <p
      data-cy="display-label"
      class="darkGray--text mb-0 body-2">
      {{ label }}
    </p>
    <a
      v-if="isLink"
      :href="to"
      data-cy="display-value"
      class="body-2 mb-0 grey--text">{{ prefix }} {{ valueProcessed }} {{ suffix }}</a>
    <p
      v-else
      class="body-2 mb-0 grey--text display-value"
      data-cy="display-value">
      {{ prefix }} {{ valueProcessed }} {{ suffix }}
    </p>
    <slot />
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number],
        default: ''
      },
      className: {
        type: String,
        default: 'xs12 sm12'
      },
      suffix: {
        type: String,
        default: ''
      },
      prefix: {
        type: String,
        default: ''
      },
      inline: {
        type: Boolean,
        default: false
      },
      table: {
        type: Boolean,
        default: true
      },
      to: {
        type: String,
        default: ''
      }
    },
    computed: {
      isLink () {
        return this.to !== ''
      },
      valueProcessed () {
        return this.value ? this.value : 'Não preenchido'
      }
    }
  }
</script>

<style lang="sass">
  .display-field .table-text
    word-wrap: break-word
    font-size: 11px
</style>
