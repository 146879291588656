<template>
  <v-card class="accept-term flow-negotiation transparent elevation-0">
    <v-card-text
      v-if="$can('guest')"
      class="px-0">
      <!--  <h3 class="body-2 primary--text">Você está a um passo de retormar seu equilíbrio financeiro!</h3> -->
      <p>Por favor, leia com atenção o Termo de Acordo abaixo.</p>

      <loading
        v-if="loadingTemplate"
        class="area-pdf only-preview " />
      <div
        v-else
        id="pdfview"
        class="area-pdf only-preview" />

      <v-checkbox
        v-if="!showReason"
        v-model="acceptTerm"
        :disabled="loadingTemplate"
        class="term-accept"
        label="Declaro que li, aceito e assino eletronicamente o presente Termo de Acordo." />

      <v-list-item
        v-if="!showReason"
        class="proposal-actions">
        <v-btn
          :disabled="!acceptTerm || loading !== 0"
          class="darkGray--text"
          depressed
          @click="setRefusal">
          {{ btnCancelLabel }}
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="!acceptTerm"
          :loading="loading !== 0"
          depressed
          color="accent"
          @click="confirm">
          {{ btnConfirmLabel }}
        </v-btn>
      </v-list-item>
    </v-card-text>

    <transition
      name="fade"
      mode="out-in"
      appear>
      <v-card-text
        v-if="showReason"
        class="px-0 pb-6 mb-6">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
          <v-layout justify-center>
            <v-flex
              xs12
              sm8
              class="pt-0">
              <v-text-field
                ref="textarea"
                v-model="reason"
                label="Qual o motivo da recusa ?"
                textarea
                :rules="requiredRules"
                required />
              <v-layout>
                <v-flex
                  xs6
                  sm6>
                  <v-btn
                    class="darkGray--text mr-1"
                    outlined
                    block
                    depressed
                    @click="backTerm">
                    {{ btnBackLabel }}
                  </v-btn>
                </v-flex>
                <v-flex
                  xs6
                  sm6>
                  <v-btn
                    class="ml-1"
                    outlined
                    :disabled="!valid"
                    color="accent"
                    block
                    @click="confirmRefuse">
                    {{ btnConfirmRefuseLabel }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
    </transition>

    <modal
      :show="showModal"
      max-width="450px"
      hide-close
      hide-actions
      @close="closeModal">
      <div slot="content">
        <template v-if="loading">
          <v-container class="px2 py2">
            <div class="show-loading">
              <loading size="150px" />
            </div>
            <h2 class="title primary--text text-center mb-2">
              Processando
            </h2>
            <p class="textColor--text text-center">
              Aguarde alguns segundos enquanto confirmamos suas informações
            </p>
          </v-container>
        </template>
        <template v-else>
          <div v-if="!isSuccess">
            <h2 class="title error--text text-center">
              <v-icon color="error">
                {{ $root.icons.state.error }}
              </v-icon> Houve um erro!!!
            </h2>
            <p class="textColor--text my-3 text-center">
              Por favor, tente novamente
            </p>
            <p class="text-center mb-0">
              <a @click="closeModal">Fechar</a>
            </p>
          </div>
        </template>
      </div>
    </modal>
  </v-card>
</template>

<script>
  import acceptTerm from '@/mixins/accept-term'
  import validate from '@/mixins/validate'
  import PDFObject from 'pdfobject'

  export default {
    mixins: [ acceptTerm, validate ],
    props: {
      proposal: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        showReason: false,
        reason: '',
        valid: true,
        loading: 0
      }
    },
    computed: {
      isMobile () {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
      },
      isLarge () {
        return this.$vuetify.breakpoint.xlOnly
      },
      btnConfirmRefuseLabel () {
        return this.isMobile ? 'Recusar' : this.isLarge ? 'Quero recusar essa proposta' : 'Recusar proposta'
      },
      btnCancelLabel () {
        return this.isMobile ? 'Recusar' : 'Recusar proposta'
      },
      btnConfirmLabel () {
        return this.isMobile ? 'Aceitar' : 'Aceitar proposta'
      },
      btnBackLabel () {
        return this.isMobile ? 'Voltar' : 'Voltar ao Termo'
      }
    },
    watch: {
      proposal (proposal) {
        this.proposal = proposal
        this.build(this.proposal)
      }
    },
    destroyed () {
      this.showModal = false
    },
    methods: {
      confirm () {
        this.loading = 1
        this
          .$store
          .dispatch('proposal/negotiationAccept', this.proposal.id)
          .then(response => {
            this.$emit('actionExecuted', 'accepted')
            this._success()
          })
          .catch(this._failure)
      },
      _success (text = 'Termo aceito com sucesso') {
        this.loading = 0
        this.isSuccess = true
        this.showModal = false
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'success', text: text})
      },
      _failure () {
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'Houve um problema'})
        this.loading = 0
        this.isSuccess = false
        this.showModal = false
      },
      setRefusal () {
        this.showReason = true
        this.$nextTick(() => {
          this.autoScroll()
          this.$refs.textarea.focus()
        })
      },
      confirmRefuse () {
        if (this.$refs.form.validate()) {
          this._refuseQuery()
        }
      },
      _refuseQuery () {
        this.showModal = true
        this.loading = 1

        const data = {
          proposalId: this.proposal.id,
          refuse: {
            reason: 'others',
            note: this.reason
          }
        }

        this
          .$store
          .dispatch('proposal/negotiationRefuse', data)
          .then(response => {
            this._success('Proposta recusada com sucesso!')
            this.showModal = false
            this.$emit('closeModal', false)
          })
          .catch(this._failure)
      },
      autoScroll () {
        var elem = document.querySelector('.modal-content')
        elem.scrollTop = 500
      },
      backTerm () {
        this.showReason = false
      }
    }
  }
</script>

<style lang="sass">
  @import '../../../assets/style/accept-terms'

  .flow-negotiation
    .area-pdf
      height: calc(90vh - 50px)
</style>
