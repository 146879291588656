<template>
  <loading v-if="loading > 0" />

  <div v-else>
    <header class="subtitle-1">
      {{ header[kind] }}
    </header>

    <ol class="mt-6">
      <li
        v-for="(question, index) in getQuestions"
        :key="question.id">
        {{ $t('surveys.' + question.id, { name: subject, company_name: company }) }}

        <v-rating
          v-model="ratings[index]"
          class="mb-4"
          color="primary"
          hover
          @input="setRating(question.id, ratings[index])" />
      </li>
    </ol>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Loading from '@/components/ui/Loading'

  export default {
    components: { Loading },
    props: {
      subject: {
        type: String,
        default: ''
      },
      company: {
        type: String,
        default: ''
      },
      kind: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        ratings: [],
        result: {},
        header: {
          'negotiation': `Essa pesquisa tem o propósito de avaliar o desempenho do Representante da ${this.company}. Garantimos que manteremos o sigilo da sua identidade. Por favor, responda às questões abaixo:`,
          'mediation': 'Essa pesquisa tem o propósito de avaliar a sua satisfação com a nossa plataforma e a qualidade das pessoas envolvidas. Garantimos que manteremos o sigilo da sua identidade. Por favor, responda às questões abaixo:'
        }
      }
    },
    computed: {
      ...mapGetters({
        getQuestions: 'surveys/questions',
        loading: 'surveys/listLoading'
      })
    },
    beforeMount () {
      this.$store.dispatch('surveys/getQuestions', this.kind)
    },
    methods: {
      setRating (questionId, rating) {
        this.result[questionId] = rating
        this.$emit('updateRating', this.result)
      }
    }
  }
</script>
