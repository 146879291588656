import PDFObject from 'pdfobject'
import termsMixin from '@/mixins/proposal-terms'
import request from '@/client/templates'

export default {
  mixins: [ termsMixin ],
  methods: {
    build (proposal) {
      this.loadingTemplate = true
      proposal.case.event.then(docEvent => {
        const event = docEvent.data()

        const discount = proposal.currentValue - proposal.installmentSelected.currentValue
        const today = new Date()
        const date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
        const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
        request
          .post('/functions/templates', {
            template: 'TermAgreement',
            params: {
              isPreview: true,
              date,
              time,
              correctionIndex: event.calculateParams.correctionIndex.toUpperCase(),
              parcel: proposal.installmentSelected.parcel,
              isParcels: proposal.installmentSelected.parcel > 1,
              termRegister: '###################',
              code: '####-####-######',
              company: {
                fantasy_name: proposal.company.fantasyName,
                cnpj: proposal.company.cnpj,
                signature: {
                  id: '#################',
                  date: '##/##/##',
                  time: '##:##:##'
                }
              },
              installments: proposal.installmentSelected.installments.map(parcel => {
                return {due_at: parcel.dueAt, number: parcel.number, value: this.$n(parcel.value, 'currency')}
              }),
              tickets: proposal.tickets.map(ticket => {
                const dueAt = new Date(ticket.dueAt.split('-').join('/')).toLocaleDateString('pt-BR')
                return {dueAt, currentValue: this.$n(ticket.currentValue, 'currency'), originalValue: this.$n(ticket.originalValue, 'currency')}
              }),
              installmentSelected: {
                originalValue: this.$n(proposal.originalValue, 'decimal'),
                currentValue: this.$n(proposal.installmentSelected.currentValue, 'decimal'),
                dueAt: proposal.installmentSelected.installments[0].dueAt,
                parcel: proposal.installmentSelected.parcel,
                parcelValue: this.$n(proposal.installmentSelected.parcelValue, 'decimal'),
                discount: this.$n(discount, 'decimal')
              },
              guest: {
                name: proposal.case.recipient.name,
                email: proposal.case.recipient.email,
                document: proposal.case.recipient.document,
                signature: {
                  id: '#################',
                  date: '##/##/##',
                  time: '##:##:##'
                }
              },
              camara: {
                alias: 'Modera',
                name: 'Modera - Câmara de Negociação e Mediação Online',
                email: 'contato@modera.app'
              },
              fine: event.calculateParams.fine + '%',
              interestRate: event.calculateParams.interestRate + '%',
              fees: event.calculateParams.fees + '%',
              originalValue: this.$n(proposal.originalValue, 'currency'),
              currentValue: this.$n(proposal.currentValue, 'currency')
            }
          })
          .then(response => {
            this.loadingTemplate = false
            this.$nextTick(() => {
              const options = { pdfOpenParams: { view: 'FitH', page: '1' } }
              PDFObject.embed(URL.createObjectURL(response.data), "#pdfview", options)
            })
          })
          .catch(_ => this.loadingTemplate = false)
      })
    }
  }
}
