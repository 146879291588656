import Loading from '@/components/ui/Loading'
import Modal from '@/components/ui/Modal'
import { mapGetters } from 'vuex'

export default {
  components: { Loading, Modal },
  data () {
    return {
      urlTemplate: '',
      acceptTerm: false,
      showModal: false,
      isSuccess: null,
      numPages: 1,
      loadingTemplate: true,
      email: ''
    }
  },
  computed: {
    ...mapGetters('global', ['getHostApi'])
  },
  methods: {
    // confirm () {
    //   this.showModal = true
    //   this.loading = 1

    //   // fake loading // sera apagado depois
    //   setTimeout(() => {
    //     this.loading = 0
    //     this.isSuccess = true
    //   }, 1000)
    // },
    backRoute () {
      this.$router.push({ name: 'ProposalRefuseQuestion' })
    },
    closeModal () {
      this.showModal = false
      this.$root.$emit('snackbar', {show: false})
      this.$nextTick(() => {
        this._resetData()
      })
    },
    _resetData () {
      this.isSuccess = null
    }
  },
  watch: {
    urlTemplate () {
      this.urlTemplate.then(pdf => {
        this.numPages = pdf.numPages
        this.loadingTemplate = false
      })
    }
  }
}
